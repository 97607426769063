import Alpine from "alpinejs";

// import CustomScrollbar from "./CustomScrollbar";
// Alpine.data("CustomScrollbar", CustomScrollbar);

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import SimpleSlider from "./SimpleSlider";
Alpine.data("SimpleSlider", SimpleSlider);

import SidebarTitles from "./SidebarTitles";
Alpine.data("SidebarTitles", SidebarTitles);

import TimeInputMask from "./TimeInputMask";
Alpine.data("TimeInputMask", TimeInputMask);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import DateInputMask from "./DateInputMask"
Alpine.data("DateInputMask", DateInputMask)

import OpenModal from "./OpenModal"
Alpine.data("OpenModal", OpenModal)

Alpine.store("mainMenu", {
    open: false,
});

Alpine.store("sessid", window.sessid);

Alpine.start();
