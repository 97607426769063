var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class Map {
    constructor({ container, center, zoom }) {
        if (!container)
            throw new Error("Контейнер не определён");
        this.container = container;
        this.createMap(center, zoom);
    }
    static loadAPI() {
        if (!this.isLoading && !this.ymapIsReady) {
            this.loader = this.startLoad();
            return this.loader;
        }
        else
            return this.loader;
    }
    static startLoad() {
        return new Promise((resolve) => {
            this.isLoading = true;
            if (Map.ymapIsReady) {
                this.isLoading = false;
                resolve("loaded");
                return;
            }
            const script = document.createElement("script");
            script.src = `https://api-maps.yandex.ru/v3/?apikey=${this.key}&lang=ru_RU`;
            script.addEventListener("load", () => __awaiter(this, void 0, void 0, function* () {
                yield ymaps3.ready;
                Map.ymapIsReady = true;
                this.isLoading = false;
                resolve("loaded");
            }));
            document.body.append(script);
        });
    }
    static createDefaultMarker() {
        const marker = document.createElement("div");
        marker.innerHTML = `
			<svg class="icon" width="45" height="56" viewBox="0 0 45 56" fill="none">
				<path d="M45 22.6835C45 35.2113 35.1123 43.2848 22.5 56C9.77609 43.7722 0 35.2113 0 22.6835C0 10.1558 10.0736 0 22.5 0C34.9264 0 45 10.1558 45 22.6835Z" fill="#1C1C1C"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M23.3858 16.0023H21.6098L21.3639 16.591L23.8799 22.5931H18.8457L17 27H19.2723L20.1929 24.8H24.8027L25.7234 27H28L23.3858 16V16.0023Z" fill="#F5F5F5"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M33.908 16.1412H31.8028V14H36V29H31.8028V26.8587H33.908V16.1412ZM11.0942 16.1412H13.1972V14H9V29H13.1972V26.8587H11.0942V16.1412Z" fill="#F5F5F5"/>
			</svg>`;
        marker.className = "default-map-marker";
        return marker;
    }
    createMap(center = [37.617635, 55.755814], zoom = 15) {
        this.ymap = new ymaps3.YMap(this.container, {
            location: {
                center,
                zoom,
            },
        }, [
            new ymaps3.YMapDefaultSchemeLayer({}),
            new ymaps3.YMapDefaultFeaturesLayer({ zIndex: 1800 }),
        ]);
    }
    setLocation(center, zoom = 15) {
        var _a;
        if (!this.ymap)
            return;
        (_a = this.ymap) === null || _a === void 0 ? void 0 : _a.setLocation({
            center,
            zoom,
        });
    }
    addDefaultMarker(coordinates) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { YMapDefaultMarker } = yield ymaps3.import("@yandex/ymaps3-markers@0.0.1");
            (_a = this.ymap) === null || _a === void 0 ? void 0 : _a.addChild(new YMapDefaultMarker({
                coordinates,
            }));
        });
    }
    addMarker(coordinates, content, props) {
        if (!this.ymap)
            return;
        const marker = new ymaps3.YMapMarker(Object.assign({ coordinates, draggable: false }, props), content);
        this.ymap.addChild(marker);
    }
    addMarkers(coordinates, content) {
        coordinates.forEach((coords) => {
            this.addMarker(coords, content(coords));
        });
    }
}
Map.key = "ca4ab438-ce96-431f-9eb5-8441c2a35bb3";
Map.ymapIsReady = false;
Map.isLoading = false;
export default Map;
