export default ({ speed = 900, spaceBetween = 0, autoHeight = false, loop = false }) => ({
    async init() {
        const { default: Swiper } = await import("../libs/Swiper");
        new Swiper(this.$refs.swiper, {
            speed,
            spaceBetween,
            autoHeight,
            loop,
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next,
            },
        });
    },
});
