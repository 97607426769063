export default () => ({
  links: [],
  init() {
    this.$refs.content.querySelectorAll("h2").forEach((title, i) => {
      title.id = `content-title-${i}`;
      this.links.push({
        title: title.innerText,
        id: title.id,
      });
    });
  },
});
